@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.infocontainer{
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* height: calc(100vh - 350px); */
    /* align-items: center; */
    margin-top: 40px;
  
}

.infoinner{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infotitle{
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    font-size: 26px;
    margin-bottom: 45px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}
.infoimgcontainer img {
    height: auto;
    width: 350px;
    border-radius: 5%;margin-bottom: 40px;
}
.backicon
{
    border-radius: 20px;
    left: 100px;
    position: absolute;
    height: 60px;
    width: 60px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    font-size: 28px;
}

.innertext{
    font-family: 'Rubik', sans-serif;
  /* padding: 40px;
   */
   /* margin-top: 50px; */
   /* margin: 40px; */
  font-size: 20px;
  margin-bottom: 100px;
}

.innercol{
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
}

.loder{
height: 100vh;
width: 100vw;
display: flex;align-items: center;
justify-content: center;
}

.loder img{
    height: 550px;
    width: auto;
}


@media only screen and (max-width: 1500px) {


    .innertext{
        font-family: 'Rubik', sans-serif;
        /* padding: 40px;
         */
         margin: 30px;
        font-size: 18px;
      }
      .innercol{
        display: flex;
        flex-direction: column;
        width: 90%;
        align-items: center;
    }
      
}   

@media only screen and (max-width: 768px) {
    .infoimgcontainer img {
        height: auto;
        width: 250px;
        border-radius: 5%;
    }

    .backicon{
        left: 20px;
        height: 40px;
        width: 40px;
        top: 85px
    }

    .innercol{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    /* .infotitle{
        align-items: flex-start;
    } */
    .infotitle{
        
        margin-bottom: 15px;
    
    }

    .loder img{
        height: 350px;
        width: auto;
    }
}