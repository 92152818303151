@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

.navbar{
    width: 100%;
    height: 70px;
    background-color: rgb(0, 0, 0);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    z-index: 10;
}

.logotxt{
    font-family: 'Rubik', sans-serif;
    /* margin-left: 70px; */
    font-weight: 600;
    font-size: 27px;
    margin-bottom: 0px;
    color: white;

}

.navbody{
    height: 100px;
    /* background-color: rgb(239, 239, 239); */
}

.ss{
    color: rgb(255, 255, 255);
}

.navbarcontainer{
    display: flex;margin-left: 70px;
}

.navbarcontainer img{
    height: 60px;
}

@media only screen and (max-width: 900px)  {
    .logotxt{
        
        
        font-size: 20px;
        margin-bottom: 0px;
        color: white;
    
    }

    .navbarcontainer{
        display: flex;margin-left: 20px;
    }
  }