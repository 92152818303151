@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.tablebody{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  /* height: calc(100vh - 100px); */
  /* background-color: rgb(239, 239, 239); */
}

.tablecontainer{
  width: 80%;
}

.titlediv{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.titlediv span{
  font-size: 18px;
  margin-left: 10px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}
 
.ant-table table {   font-family: 'Rubik', sans-serif; }

.badgeno{
  height: 45px;
  width: 45px;
  background-color: rgb(253, 86, 86);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 21px;
  border-radius: 10px;
}

.table:hover
{
  cursor: pointer;
}

.footer{
  position: fixed;
  bottom: 0px;
}

.footer p{
  
  font-family: 'Rubik', sans-serif;

}


@media only screen and (max-width: 900px)  {
  .tablecontainer{
    width: 100%;
    overflow-x: scroll;
  }

  .titlediv{
    margin-left: 20px;
  }

  
}